/* You can add global styles to this file, and also import other style files */
//@import "~bootstrap/dist/css/bootstrap.css";
//@import "~font-awesome/css/font-awesome.css";
//@import "assets/styles/style";
@import "./assets/styles/style-norelian.css";

html {
  scrollbar-width: none;
}

body {
  margin: 0;
}


.no-padding-left {
  padding-left: 0px !important;

}

.no-padding-right {
  padding-right: 0px !important;

}

.no-padding {
  padding: 0px !important;

}

hr {
  border: 1px solid rgba(0, 0, 0, 0.12) !important
}

.float-top {
  margin-top: 25px !important;
}

.text-small {
  color: #C6C3BD !important;
}

.text-p {
  color: #999999 !important;
  font-size: 13px !important;
}

.text-inicial>span {
  font-size: 19px !important;
  font-weight: 500;
  color: #686C6D !important;
}


select.browser-default {
  background: #F3F3F4 !important;
  border-bottom: 1px solid #686C6D !important;
  color: black !important;
}

select.browser-default>option {
  color: black !important;
}

select:focus {
  outline: none !important;
}

h6>strong {
  color: #686C6D !important;
}

.input_add {
  width: 90% !important;
}

.pointer {
  cursor: pointer;
}

.btn-small i {
  font-size: 1rem !important;
}

.fa-sm {
  font-size: 12px !important;
  line-height: .75em;
}

.up {
  display: block !important;
  z-index: 1003 !important;
  width: 400px;
  top: 25px !important;
  transform: scaleX(1) scaleY(1);
}

.ups {
  z-index: 1002 !important;
  display: block !important;
  opacity: 0.5 !important;
}

.downs {
  display: none !important;
}

.padding5 {
  padding: 5px !important;
}

.padding0 {
  padding: 0px !important;
}

.padding5top {
  padding-top: 5px !important;
}

.padding10 {
  padding: 10px !important;
}

.padding15 {
  padding: 15px !important;
}

.ngx-file-drop__drop-zone[_ngcontent-c4] {
  border: none !important;
}

.ngx-file-drop__drop-zone[_ngcontent-c5] {
  border: none !important;
}

.ngx-file-drop__drop-zone[_ngcontent-c6] {
  border: none !important;
}

.ngx-file-drop__drop-zone[_ngcontent-c3] {
  border: none !important;
}

.ngx-file-drop__drop-zone[_ngcontent-c2] {
  border: none !important;
}

.ngx-file-drop__drop-zone[_ngcontent-c1] {
  border: none !important;
}

.ngx-file-drop__drop-zone {
  border: none !important;
}


.active {
  color: #686C6D !important
}

input.valid:not([type]),
input.valid:not([type]):focus,
input.valid[type=text]:not(.browser-default),
input.valid[type=text]:not(.browser-default):focus,
input.valid[type=password]:not(.browser-default),
input.valid[type=password]:not(.browser-default):focus,
input.valid[type=email]:not(.browser-default),
input.valid[type=email]:not(.browser-default):focus,
input.valid[type=url]:not(.browser-default),
input.valid[type=url]:not(.browser-default):focus,
input.valid[type=time]:not(.browser-default),
input.valid[type=time]:not(.browser-default):focus,
input.valid[type=date]:not(.browser-default),
input.valid[type=date]:not(.browser-default):focus,
input.valid[type=datetime]:not(.browser-default),
input.valid[type=datetime]:not(.browser-default):focus,
input.valid[type=datetime-local]:not(.browser-default),
input.valid[type=datetime-local]:not(.browser-default):focus,
input.valid[type=tel]:not(.browser-default),
input.valid[type=tel]:not(.browser-default):focus,
input.valid[type=number]:not(.browser-default),
input.valid[type=number]:not(.browser-default):focus,
input.valid[type=search]:not(.browser-default),
input.valid[type=search]:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid>input.select-dropdown {
  border-bottom: 1px solid #1AB394 !important;
  -webkit-box-shadow: 0 1px 0 0 #1AB394 !important;
  box-shadow: 0 1px 0 0 #1AB394 !important;
}

.selectBeutify {
  margin-top: 30px !important;
}

.selectBeutify>select {
  padding-left: 0px !important;
  border-left-width: 0px !important;
}

.selectBeutify0>select {
  padding-left: 0px !important;
  border-left-width: 0px !important;
}

.input-field-pad>label {
  left: 0px !important;
}

.btn-upload {
  border-top-width: 0px !important;
  border-left-width: 0px !important;
  border-bottom-width: 0px !important;
  border-right-width: 0px !important;
  background: transparent !important;
  margin-top: 100% !important;
  color: #1AB394 !important;
  cursor: pointer !important;
}

.btn-upload:focus {
  border: none !important;
}



.pointer {
  cursor: pointer !important;
}

.margin0 {
  margin: 0px !important;
}

h1 {
  font-size: 3.02rem !important;
  color: #999c9e;
}

h2 {
  font-size: 2.56rem !important;
  color: #999c9e;
}

h3 {
  font-size: 1.28rem !important;
  color: #999c9e;
}

h4 {
  font-size: 1.17rem !important;
  color: #999c9e;
}

span,
a,
b,
p,
strong,
u {
  color: #2F4050;
}

.btn,
.btn-large,
.btn-small {
  background-color: none !important;
}

.btn-flat {
  background-color: transparent;
  border: solid 1px #1AB394 !important;
  color: #1AB394 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-flat:hover,
.btn-flat-ant:hover {
  background-color: none !important;
}

.btn-flat-ant {
  background-color: transparent;
  color: #1AB394 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.button-options {
  background: #C6C3BD !important;
}

.button-options.active {
  background: #1AB394 !important;
  color: #FFF !important;
}



.btn:hover,
.btn-large:hover,
.btn-small:hover {
  border-bottom: 2px solid #1AB394;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-small-s {
  padding: 0 10px !important;
  height: 25px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  line-height: 25px !important;
  font-size: 13px !important;
  border-radius: 4px !important;
}

.btn-small-xs {
  padding: 0 10px !important;
  height: 15px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  line-height: 15px !important;
  font-size: 13px !important;
  border-radius: 4px !important;
}

.mat-snack-bar-container {
  background: #ef5350 !important;
  color: white !important;
}

.mat-simple-snackbar>span {
  color: white !important;
  font-size: 15px !important;
}

.mat-simple-snackbar-action>.mat-button {
  box-shadow: none !important;
}

.mat-simple-snackbar-action>.mat-button>.mat-button-wrapper {
  font-size: 22px !important;
}

.mat-snack-bar-container {
  min-width: 100px !important;
}

.pointer {
  cursor: pointer;
}

.margin-top0 {
  margin-top: 0px !important;
}

.margin-bottom0 {
  margin-bottom: 0px !important;
}

html,
h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
p,
input,
label {
  font-family: 'Open Sans', sans-serif !important;
}

body::-webkit-scrollbar {
  width: 5px !important;
}

body::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-radius: 10px !important;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent !important;
  border-radius: 10px !important;
}

.inDropDown {
  display: block;
  height: auto;
  max-height: 400px !important;
  min-width: 200px;
  scroll-behavior: smooth;
  height: auto;
  transform-origin: 0px 0px;
  opacity: 1;
  transform: scaleX(1) scaleY(1);
  position: relative;
  padding-bottom: 10px;
}

.inDropDown li {
  min-height: 35px !important;
}

.outDropDown {
  display: none;
}

.backgroundBase {
  background: #F3F3F4;
}

.backgroudWhite {
  background: #FFFFFF;
}

.padding32 {
  padding: 32px !important;
}

.ngx-pagination .current {
  background: #999c9e !important;
  color: white !important;
}

.ngx-pagination .current>span {
  color: white !important;
}

#columnas {
  @media only screen and (max-width: 600px) {
    column-count: 1;
  }

  @media only screen and (min-width: 601px) {
    column-count: 2;
  }

  @media only screen and (min-width: 900px) {
    column-count: 3;
  }

  column-gap: 15px !important;
  column-fill: auto !important;
  word-break: break-all;

}

.cardList {
  display: flow-root;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid !important;

}

.cardList img {
  width: 100% !important;
}

.padding12 {
  padding: 12px !important;
}

.text-card-16 {
  text-align: left;
  color: #2F4050;
  size: 16px;
  font-weight: 600;
}

.padding-top-16 {
  padding-top: 16px;
}

.margin-top-24 {
  margin-top: 24px;
}

.pointer1 {
  cursor: pointer;
  opacity: 0.8;
}

.pointer1:hover {
  opacity: 1.0;
}

.shadown-cards {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
}

.input-field .helper-text {
  color: red;
}

select option:hover {
  background-color: #c3c3c3 !important;
  cursor: pointer
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
